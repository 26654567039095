import { EntityTranslation } from './EntityTranslation';

export interface StaticPage {
  id: number;
  name: string;
  content: string;
  translations: EntityTranslation[];
}

export enum DefaultStaticPages {
  // eslint-disable-next-line no-unused-vars
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  // eslint-disable-next-line no-unused-vars
  PROJECT_INFORMATION = 'PROJECT_INFORMATION',
}
