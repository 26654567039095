export const CREATE_ANSWER_START = 'CREATE_ANSWER_START';
export const CREATE_ANSWER_SUCCESS = 'CREATE_ANSWER_SUCCESS';
export const CREATE_ANSWER_FAIL = 'CREATE_ANSWER_FAIL';

export const UPDATE_ANSWER_START = 'UPDATE_ANSWER_START';
export const UPDATE_ANSWER_SUCCESS = 'UPDATE_ANSWER_SUCCESS';
export const UPDATE_ANSWER_FAIL = 'UPDATE_ANSWER_FAIL';

export const DELETE_ANSWER_START = 'DELETE_ANSWER_START';
export const DELETE_ANSWER_SUCCESS = 'DELETE_ANSWER_SUCCESS';
export const DELETE_ANSWER_FAIL = 'DELETE_ANSWER_FAIL';

export const RESET_ANSWER_STORE = 'RESET_ANSWER_STORE';

export const SET_SELECTED_ANSWERS = 'SET_SELECTED_ANSWERS';
export const RESET_SELECTED_ANSWERS = 'RESET_SELECTED_ANSWERS';

export type AnswerActionTypes =
  | typeof CREATE_ANSWER_START
  | typeof CREATE_ANSWER_SUCCESS
  | typeof CREATE_ANSWER_FAIL
  | typeof UPDATE_ANSWER_START
  | typeof UPDATE_ANSWER_SUCCESS
  | typeof UPDATE_ANSWER_FAIL
  | typeof DELETE_ANSWER_START
  | typeof DELETE_ANSWER_SUCCESS
  | typeof DELETE_ANSWER_FAIL
  | typeof RESET_ANSWER_STORE
  | typeof SET_SELECTED_ANSWERS
  | typeof RESET_SELECTED_ANSWERS;
