export const FETCH_SKILL_GROUPS_START = 'FETCH_SKILL_GROUPS_START';
export const FETCH_SKILL_GROUPS_SUCCESS = 'FETCH_SKILL_GROUPS_SUCCESS';
export const FETCH_SKILL_GROUPS_FAIL = 'FETCH_SKILL_GROUPS_FAIL';

export const CREATE_SKILL_GROUP_START = 'CREATE_SKILL_GROUP_START';
export const CREATE_SKILL_GROUP_SUCCESS = 'CREATE_SKILL_GROUP_SUCCESS';
export const CREATE_SKILL_GROUP_FAIL = 'CREATE_SKILL_GROUP_FAIL';

export const UPDATE_SKILL_GROUP_START = 'UPDATE_SKILL_GROUP_START';
export const UPDATE_SKILL_GROUP_SUCCESS = 'UPDATE_SKILL_GROUP_SUCCESS';
export const UPDATE_SKILL_GROUP_FAIL = 'UPDATE_SKILL_GROUP_FAIL';

export const DELETE_SKILL_GROUP_START = 'DELETE_SKILL_GROUP_START';
export const DELETE_SKILL_GROUP_SUCCESS = 'DELETE_SKILL_GROUP_SUCCESS';
export const DELETE_SKILL_GROUP_FAIL = 'DELETE_SKILL_GROUP_FAIL';

export const RESET_SKILL_GROUP_STORE = 'RESET_SKILL_GROUP_STORE';
export const RESET_CREATED_SKILL_GROUP = 'RESET_CREATED_SKILL_GROUP';

export type SkillGroupActionTypes =
  | typeof FETCH_SKILL_GROUPS_START
  | typeof FETCH_SKILL_GROUPS_SUCCESS
  | typeof FETCH_SKILL_GROUPS_FAIL
  | typeof CREATE_SKILL_GROUP_START
  | typeof CREATE_SKILL_GROUP_SUCCESS
  | typeof CREATE_SKILL_GROUP_FAIL
  | typeof UPDATE_SKILL_GROUP_START
  | typeof UPDATE_SKILL_GROUP_SUCCESS
  | typeof UPDATE_SKILL_GROUP_FAIL
  | typeof RESET_SKILL_GROUP_STORE
  | typeof RESET_CREATED_SKILL_GROUP
  | typeof DELETE_SKILL_GROUP_START
  | typeof DELETE_SKILL_GROUP_SUCCESS
  | typeof DELETE_SKILL_GROUP_FAIL;
