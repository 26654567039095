import * as actionTypes from './actionTypes';
import { SkillActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Skill } from '../../domain/Skill';

export type SkillStateType = {
  skillsList: ListResults<Skill> | null;
  skillsLoading: boolean;
  skillsError: HttpError;
  skillsListUpdateNeeded: boolean;
  skillCreateLoading: boolean;
  skillCreateError: HttpError;
  skillCreateSuccess: boolean;
  createdSkill: Skill | null;
  skillUpdateLoading: boolean;
  skillUpdateError: HttpError;
  skillUpdateSuccess: boolean;
  skill: Skill | null;
  skillLoading: boolean;
  skillError: HttpError;
  skillDeleteLoading: boolean;
  skillDeleteError: HttpError;
  skillDeleteSuccess: boolean;
  publicSkills: Skill[];
  publicSkillsLoading: boolean;
  publicSkillsError: HttpError;
};

export type SkillActionType = SkillStateType & {
  type: SkillActionTypes;
};

export const initialState: SkillStateType = {
  skillsList: null,
  skillsLoading: true,
  skillsError: null,
  skillsListUpdateNeeded: false,
  skillCreateLoading: false,
  skillCreateError: null,
  skillCreateSuccess: false,
  createdSkill: null,
  skillUpdateLoading: false,
  skillUpdateError: null,
  skillUpdateSuccess: false,
  skill: null,
  skillLoading: false,
  skillError: null,
  skillDeleteLoading: false,
  skillDeleteError: null,
  skillDeleteSuccess: false,
  publicSkills: [],
  publicSkillsLoading: false,
  publicSkillsError: null,
};

const fetchSkillsStart = (state: SkillStateType): SkillStateType => ({
  ...state,
  skillsLoading: true,
});

const fetchSkillsSuccess = (
  state: SkillStateType,
  action: SkillActionType,
): SkillStateType => ({
  ...state,
  skillsList: action.skillsList,
  skillsLoading: false,
  skillsError: null,
  skillsListUpdateNeeded: false,
});

const fetchSkillsFail = (
  state: SkillStateType,
  action: SkillActionType,
): SkillStateType => ({
  ...state,
  skillsError: action.skillsError,
  skillsLoading: false,
});

const createSkillStart = (state: SkillStateType): SkillStateType => ({
  ...state,
  skillCreateLoading: true,
});

const createSkillSuccess = (
  state: SkillStateType,
  action: SkillActionType,
): SkillStateType => ({
  ...state,
  skillCreateLoading: false,
  skillCreateError: null,
  skillCreateSuccess: true,
  skillsListUpdateNeeded: true,
  createdSkill: action.createdSkill,
  skillsList: state.skillsList
    ? {
        total: state.skillsList.total + 1,
        result: [...state.skillsList.result, action.createdSkill] as Skill[],
      }
    : null,
});

const createSkillFail = (
  state: SkillStateType,
  action: SkillActionType,
): SkillStateType => ({
  ...state,
  skillCreateLoading: false,
  skillCreateError: action.skillCreateError,
});

const updateSkillStart = (state: SkillStateType): SkillStateType => ({
  ...state,
  skillUpdateLoading: true,
});

const updateSkillSuccess = (state: SkillStateType): SkillStateType => ({
  ...state,
  skillUpdateLoading: false,
  skillUpdateError: null,
  skillUpdateSuccess: true,
  skillsListUpdateNeeded: true,
});

const updateSkillFail = (
  state: SkillStateType,
  action: SkillActionType,
): SkillStateType => ({
  ...state,
  skillUpdateLoading: false,
  skillUpdateError: action.skillUpdateError,
});

const deleteSkillStart = (state: SkillStateType): SkillStateType => ({
  ...state,
  skillDeleteLoading: true,
});

const deleteSkillSuccess = (state: SkillStateType): SkillStateType => ({
  ...state,
  skillDeleteLoading: false,
  skillDeleteError: null,
  skillDeleteSuccess: true,
  skillsListUpdateNeeded: true,
});

const deleteSkillFail = (
  state: SkillStateType,
  action: SkillActionType,
): SkillStateType => ({
  ...state,
  skillDeleteLoading: false,
  skillDeleteError: action.skillDeleteError,
});

const fetchPublicSkillsStart = (state: SkillStateType): SkillStateType => ({
  ...state,
  publicSkillsLoading: true,
});

const fetchPublicSkillsSuccess = (
  state: SkillStateType,
  action: SkillActionType,
): SkillStateType => ({
  ...state,
  publicSkills: action.publicSkills,
  publicSkillsLoading: false,
  publicSkillsError: null,
});

const fetchPublicSkillsFail = (
  state: SkillStateType,
  action: SkillActionType,
): SkillStateType => ({
  ...state,
  publicSkillsError: action.publicSkillsError,
  publicSkillsLoading: false,
});

const resetCreatedSkill = (state: SkillStateType): SkillStateType => ({
  ...state,
  createdSkill: null,
});

const resetSkillStore = (): SkillStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: SkillActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_SKILLS_START:
      return fetchSkillsStart(state);
    case actionTypes.FETCH_SKILLS_SUCCESS:
      return fetchSkillsSuccess(state, action);
    case actionTypes.FETCH_SKILLS_FAIL:
      return fetchSkillsFail(state, action);
    case actionTypes.CREATE_SKILL_START:
      return createSkillStart(state);
    case actionTypes.CREATE_SKILL_SUCCESS:
      return createSkillSuccess(state, action);
    case actionTypes.CREATE_SKILL_FAIL:
      return createSkillFail(state, action);
    case actionTypes.UPDATE_SKILL_START:
      return updateSkillStart(state);
    case actionTypes.UPDATE_SKILL_SUCCESS:
      return updateSkillSuccess(state);
    case actionTypes.UPDATE_SKILL_FAIL:
      return updateSkillFail(state, action);
    case actionTypes.DELETE_SKILL_START:
      return deleteSkillStart(state);
    case actionTypes.DELETE_SKILL_SUCCESS:
      return deleteSkillSuccess(state);
    case actionTypes.DELETE_SKILL_FAIL:
      return deleteSkillFail(state, action);
    case actionTypes.FETCH_PUBLIC_SKILLS_START:
      return fetchPublicSkillsStart(state);
    case actionTypes.FETCH_PUBLIC_SKILLS_SUCCESS:
      return fetchPublicSkillsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_SKILLS_FAIL:
      return fetchPublicSkillsFail(state, action);
    case actionTypes.RESET_SKILL_STORE:
      return resetSkillStore();
    case actionTypes.RESET_CREATED_SKILL:
      return resetCreatedSkill(state);
    default:
      return state;
  }
};

export default reducer;
