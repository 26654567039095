import React from 'react';
import styles from './CookieBar.module.scss';
import Button from '../../common/Button/Button';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';

type Props = {
  setIsCookieBarVisible: (value: boolean) => void;
};

export const CookieBar = ({ setIsCookieBarVisible }: Props) => {
  const intl = useIntl();

  const handleButtonClick = (isAccepted: boolean) => {
    localStorage.setItem('isCookiesAllowed', isAccepted.toString());
    setIsCookieBarVisible(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.messageContainer}>
        {translate(intl, 'COOKIE_BAR.MESSAGE')}
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          onClick={() => handleButtonClick(false)}
          buttonVariant="text"
          className={styles.rejectButton}
          isRippleDisabled
        >
          {translate(intl, 'COOKIE_BAR.REJECT_COOKIES')}
        </Button>
        <Button
          onClick={() => handleButtonClick(true)}
          buttonVariant="contained"
          color="success"
        >
          {translate(intl, 'COOKIE_BAR.ALLOW_COOKIES')}
        </Button>
      </div>
    </div>
  );
};

export default CookieBar;
