export const FETCH_SKILLS_START = 'FETCH_SKILLS_START';
export const FETCH_SKILLS_SUCCESS = 'FETCH_SKILLS_SUCCESS';
export const FETCH_SKILLS_FAIL = 'FETCH_SKILLS_FAIL';

export const FETCH_PUBLIC_SKILLS_START = 'FETCH_PUBLIC_SKILLS_START';
export const FETCH_PUBLIC_SKILLS_SUCCESS = 'FETCH_PUBLIC_SKILLS_SUCCESS';
export const FETCH_PUBLIC_SKILLS_FAIL = 'FETCH_PUBLIC_SKILLS_FAIL';

export const CREATE_SKILL_START = 'CREATE_SKILL_START';
export const CREATE_SKILL_SUCCESS = 'CREATE_SKILL_SUCCESS';
export const CREATE_SKILL_FAIL = 'CREATE_SKILL_FAIL';

export const UPDATE_SKILL_START = 'UPDATE_SKILL_START';
export const UPDATE_SKILL_SUCCESS = 'UPDATE_SKILL_SUCCESS';
export const UPDATE_SKILL_FAIL = 'UPDATE_SKILL_FAIL';

export const DELETE_SKILL_START = 'DELETE_SKILL_START';
export const DELETE_SKILL_SUCCESS = 'DELETE_SKILL_SUCCESS';
export const DELETE_SKILL_FAIL = 'DELETE_SKILL_FAIL';

export const RESET_SKILL_STORE = 'RESET_SKILL_STORE';
export const RESET_CREATED_SKILL = 'RESET_CREATED_SKILL';

export type SkillActionTypes =
  | typeof FETCH_SKILLS_START
  | typeof FETCH_SKILLS_SUCCESS
  | typeof FETCH_SKILLS_FAIL
  | typeof FETCH_PUBLIC_SKILLS_START
  | typeof FETCH_PUBLIC_SKILLS_SUCCESS
  | typeof FETCH_PUBLIC_SKILLS_FAIL
  | typeof CREATE_SKILL_START
  | typeof CREATE_SKILL_SUCCESS
  | typeof CREATE_SKILL_FAIL
  | typeof UPDATE_SKILL_START
  | typeof UPDATE_SKILL_SUCCESS
  | typeof UPDATE_SKILL_FAIL
  | typeof RESET_SKILL_STORE
  | typeof RESET_CREATED_SKILL
  | typeof DELETE_SKILL_START
  | typeof DELETE_SKILL_SUCCESS
  | typeof DELETE_SKILL_FAIL;
