export const FETCH_QUESTIONS_START = 'FETCH_QUESTIONS_START';
export const FETCH_QUESTIONS_SUCCESS = 'FETCH_QUESTIONS_SUCCESS';
export const FETCH_QUESTIONS_FAIL = 'FETCH_QUESTIONS_FAIL';

export const CREATE_QUESTION_START = 'CREATE_QUESTION_START';
export const CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS';
export const CREATE_QUESTION_FAIL = 'CREATE_QUESTION_FAIL';

export const UPDATE_QUESTION_START = 'UPDATE_QUESTION_START';
export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS';
export const UPDATE_QUESTION_FAIL = 'UPDATE_QUESTION_FAIL';

export const DELETE_QUESTION_START = 'DELETE_QUESTION_START';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
export const DELETE_QUESTION_FAIL = 'DELETE_QUESTION_FAIL';

export const BULK_CREATE_QUESTIONS_START = 'BULK_CREATE_QUESTIONS_START';
export const BULK_CREATE_QUESTIONS_SUCCESS = 'BULK_CREATE_QUESTIONS_SUCCESS';
export const BULK_CREATE_QUESTIONS_FAIL = 'BULK_CREATE_QUESTIONS_FAIL';

export const SET_SELECTED_QUESTIONS = 'SET_SELECTED_QUESTIONS';
export const RESET_SELECTED_QUESTIONS = 'RESET_SELECTED_QUESTIONS';

export const RESET_QUESTION_STORE = 'RESET_QUESTION_STORE';

export type QuestionActionTypes =
  | typeof FETCH_QUESTIONS_START
  | typeof FETCH_QUESTIONS_SUCCESS
  | typeof FETCH_QUESTIONS_FAIL
  | typeof CREATE_QUESTION_START
  | typeof CREATE_QUESTION_SUCCESS
  | typeof CREATE_QUESTION_FAIL
  | typeof UPDATE_QUESTION_START
  | typeof UPDATE_QUESTION_SUCCESS
  | typeof UPDATE_QUESTION_FAIL
  | typeof DELETE_QUESTION_START
  | typeof DELETE_QUESTION_SUCCESS
  | typeof DELETE_QUESTION_FAIL
  | typeof BULK_CREATE_QUESTIONS_START
  | typeof BULK_CREATE_QUESTIONS_SUCCESS
  | typeof BULK_CREATE_QUESTIONS_FAIL
  | typeof SET_SELECTED_QUESTIONS
  | typeof RESET_SELECTED_QUESTIONS
  | typeof RESET_QUESTION_STORE;
