import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { translate } from '../../utility/messageTranslator/translate';
import logo from '../../assets/FSC-logo-color.svg';
import styles from './PublicLayout.module.scss';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { routes } from '../../config/Router/routes';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import MenuIcon from '@mui/icons-material/Menu';
import CookieBar from '../../component/CookieBar/CookieBar';
import euLogo from '../../assets/eu_logo.png';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';

export enum NavigationScroll {
  // eslint-disable-next-line no-unused-vars
  FOOTER = 'homepage-footer',
  // eslint-disable-next-line no-unused-vars
  HERO = 'homepage-hero',
  // eslint-disable-next-line no-unused-vars
  HOW_IT_WORKS = 'homepage-how-it-works',
  // eslint-disable-next-line no-unused-vars
  ASSESSMENTS = 'homepage-assessments',
  // eslint-disable-next-line no-unused-vars
  PROJECT_INFORMATION = 'project-information',
}

export type Props = {
  children: ReactNode;
  isAssessmentAndPublicSkillsLoading: boolean;
};

const MOBILE_BREAK_POINT = 900;

export const PublicLayout = ({
  children,
  isAssessmentAndPublicSkillsLoading,
}: Props) => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const { width } = useWindowSize();

  useEffect(() => {
    if (isMobileMenuOpen && width && width >= MOBILE_BREAK_POINT) {
      setIsMobileMenuOpen(false);
    }
  }, [width]);

  const navigationItems = [
    {
      name: translate(intl, 'HEADER.HOME'),
      scrollTo: NavigationScroll.HERO,
    },
    {
      name: translate(intl, 'HEADER.HOW_IT_WORKS'),
      scrollTo: NavigationScroll.HOW_IT_WORKS,
    },
    {
      name: translate(intl, 'HEADER.ASSESSEMENTS'),
      scrollTo: NavigationScroll.ASSESSMENTS,
    },
    {
      name: translate(intl, 'HEADER.DASHBOARD'),
      scrollTo: NavigationScroll.FOOTER,
    },
    {
      name: translate(intl, 'HEADER.PROJECT_INFORMATION'),
      scrollTo: NavigationScroll.PROJECT_INFORMATION,
    },
  ];

  const onNavigationItemClick = (scrollToId: string) => {
    if (location.pathname !== routes.homepage) {
      navigate(routes.homepage);

      return;
    }

    if (scrollToId === NavigationScroll.PROJECT_INFORMATION) {
      navigate(NavigationScroll.PROJECT_INFORMATION);

      return;
    }

    const section = document.querySelector(`#${scrollToId}`);

    if (section) {
      const options = {
        duration: 0,
        delay: 0,
        smooth: true,
        offset: -100,
      };

      scroller.scrollTo(scrollToId, options);
      setIsMobileMenuOpen(false);
    }
  };

  const HamburgerMenu = () => {
    const hamburgerMenuRef = useRef<HTMLDivElement>(null);
    const languageSwitcherPopoverListRef = useRef<HTMLUListElement>(null);

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
      if (isMobileMenuOpen && !isOpen) {
        return setIsOpen(true);
      }

      return setIsOpen(false);
    }, []);

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const handleClickOutside = (event: MouseEvent) => {
      const isClickedInsideLanguageSwitcher =
        languageSwitcherPopoverListRef.current &&
        languageSwitcherPopoverListRef.current.contains(event.target as Node);

      if (
        hamburgerMenuRef.current &&
        !hamburgerMenuRef.current.contains(event.target as Node) &&
        !isClickedInsideLanguageSwitcher
      ) {
        setIsMobileMenuOpen(false);
      }
    };

    return (
      <>
        <div>
          <MenuIcon onClick={() => setIsMobileMenuOpen(true)} />
        </div>
        <div
          className={cx(styles.hamburgerNavigation, {
            [styles.isOpen]: isOpen,
          })}
          ref={hamburgerMenuRef}
        >
          <div className={styles.hamburgerIcons}>
            <img src={logo} />
            <MenuIcon onClick={() => setIsMobileMenuOpen(false)} />
          </div>
          <div className={styles.hamburgerItems}>
            {navigationItems.map((item, i) => {
              return (
                <p key={i} onClick={() => onNavigationItemClick(item.scrollTo)}>
                  {item.name}
                </p>
              );
            })}
            <LanguageSwitcher
              languageSwitcherPopoverListRef={languageSwitcherPopoverListRef}
              closeMobileMenu={() => setIsMobileMenuOpen(false)}
            />
          </div>
        </div>
      </>
    );
  };

  const NavigationMenu = () => {
    return (
      <div className={styles.headerNavigationItems}>
        {navigationItems.map((item, i) => {
          return (
            <p key={i} onClick={() => onNavigationItemClick(item.scrollTo)}>
              {item.name}
            </p>
          );
        })}
        <LanguageSwitcher />
      </div>
    );
  };

  const [isCookiesBarVisible, setIsCookieBarVisible] = useState<boolean>(false);

  useEffect(() => {
    if (localStorage.getItem('isCookiesAllowed') === null) {
      setIsCookieBarVisible(true);
    }
  }, []);

  return (
    <>
      <header className={styles.headerContainer}>
        <div className={styles.headerInner}>
          <div className={styles.headerLogo}>
            <Link to={routes.homepage}>
              <img src={logo} />
            </Link>
          </div>
          {width && width >= MOBILE_BREAK_POINT ? (
            <NavigationMenu />
          ) : (
            <HamburgerMenu />
          )}
        </div>
      </header>
      {children}
      {!isAssessmentAndPublicSkillsLoading && (
        <footer className={styles.footer}>
          <div className={styles.imageContainer}>
            <img className={styles.image} src={euLogo} />
          </div>
          <div className={styles.textContainer}>
            <p className={styles.text}>
              <span className={styles.copyrightText}>
                {translate(intl, 'FOOTER.COPYRIGHT_TEXT')}
              </span>
              {translate(intl, 'FOOTER.TEXT')}
            </p>
            <p className={styles.projectNumber}>
              {translate(intl, 'FOOTER.PROJECT_NUMBER')}
            </p>
            <Link
              className={styles.dataPrivacyStatement}
              to={routes.public.privacyPolicy}
            >
              {translate(intl, 'FOOTER.DATA_PRIVACY_STATEMENT')}
            </Link>
          </div>
        </footer>
      )}
      {isCookiesBarVisible && (
        <CookieBar setIsCookieBarVisible={setIsCookieBarVisible} />
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isAssessmentAndPublicSkillsLoading:
    state.assessment.publicAssessmentLoading || state.skill.publicSkillsLoading,
});

export default connect(mapStateToProps)(PublicLayout);
