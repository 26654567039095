export const FETCH_STATIC_PAGES_START = 'FETCH_STATIC_PAGES_START';
export const FETCH_STATIC_PAGES_SUCCESS = 'FETCH_STATIC_PAGES_SUCCESS';
export const FETCH_STATIC_PAGES_FAIL = 'FETCH_STATIC_PAGES_FAIL';

export const FETCH_PUBLIC_STATIC_PAGE_START = 'FETCH_PUBLIC_STATIC_PAGE_START';
export const FETCH_PUBLIC_STATIC_PAGE_SUCCESS =
  'FETCH_PUBLIC_STATIC_PAGE_SUCCESS';
export const FETCH_PUBLIC_STATIC_PAGE_FAIL = 'FETCH_PUBLIC_STATIC_PAGE_FAIL';

export const UPDATE_STATIC_PAGE_START = 'UPDATE_STATIC_PAGE_START';
export const UPDATE_STATIC_PAGE_SUCCESS = 'UPDATE_STATIC_PAGE_SUCCESS';
export const UPDATE_STATIC_PAGE_FAIL = 'UPDATE_STATIC_PAGE_FAIL';

export const RESET_PUBLIC_STATIC_PAGE_STORE = 'RESET_PUBLIC_STATIC_PAGE_STORE';
export const RESET_STATIC_PAGE_STORE = 'RESET_STATIC_PAGE_STORE';

export type StaticPageActionTypes =
  | typeof FETCH_STATIC_PAGES_START
  | typeof FETCH_STATIC_PAGES_SUCCESS
  | typeof FETCH_STATIC_PAGES_FAIL
  | typeof FETCH_PUBLIC_STATIC_PAGE_START
  | typeof FETCH_PUBLIC_STATIC_PAGE_SUCCESS
  | typeof FETCH_PUBLIC_STATIC_PAGE_FAIL
  | typeof UPDATE_STATIC_PAGE_START
  | typeof UPDATE_STATIC_PAGE_SUCCESS
  | typeof UPDATE_STATIC_PAGE_FAIL
  | typeof RESET_PUBLIC_STATIC_PAGE_STORE
  | typeof RESET_STATIC_PAGE_STORE;
