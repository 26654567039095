import * as actionTypes from './actionTypes';
import { LanguageActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Language } from '../../domain/Language';
import { ListResults } from '../../common/List/List';

export type LanguageStateType = {
  language: Language | null;
  languageLoading: boolean;
  languageError: HttpError;
  languages: Language[];
  languagesLoading: boolean;
  languagesError: HttpError;
  languagesList: ListResults<Language> | null;
  languagesListLoading: boolean;
  languagesListError: HttpError;
  languageCreateLoading: boolean;
  languageCreateError: HttpError;
  languageCreateSuccess: boolean;
  createdLanguage: Language | null;
  languagesListUpdateNeeded: boolean;
  languageStatusChangeLoading: boolean;
  languageStatusChangeError: HttpError;
  languageStatusChangeSuccess: boolean;
  languageUpdateLoading: boolean;
  languageUpdateError: HttpError;
  languageUpdateSuccess: boolean;
};

export type LanguageActionType = LanguageStateType & {
  type: LanguageActionTypes;
};

export const initialState: LanguageStateType = {
  language: null,
  languageLoading: false,
  languageError: null,
  languages: [],
  languagesLoading: false,
  languagesError: null,
  languagesList: null,
  languagesListLoading: true,
  languagesListError: null,
  languageCreateLoading: false,
  languageCreateError: null,
  languageCreateSuccess: false,
  createdLanguage: null,
  languagesListUpdateNeeded: false,
  languageStatusChangeLoading: false,
  languageStatusChangeError: null,
  languageStatusChangeSuccess: false,
  languageUpdateLoading: false,
  languageUpdateError: null,
  languageUpdateSuccess: false,
};

const fetchLanguageStart = (state: LanguageStateType): LanguageStateType => ({
  ...state,
  languageLoading: true,
});

const fetchLanguageSuccess = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  language: action.language,
  languageLoading: false,
  languageError: null,
});

const fetchLanguageFail = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  languageError: action.languageError,
  languageLoading: false,
});

const fetchLanguagesStart = (state: LanguageStateType): LanguageStateType => ({
  ...state,
  languagesLoading: true,
});

const fetchLanguagesSuccess = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  languages: action.languages,
  languagesLoading: false,
  languagesError: null,
});

const fetchLanguagesFail = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  languagesError: action.languagesError,
  languagesLoading: false,
});

const fetchLanguagesListStart = (
  state: LanguageStateType,
): LanguageStateType => ({
  ...state,
  languagesListLoading: true,
});

const fetchLanguagesListSuccess = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  languagesListLoading: false,
  languagesList: action.languagesList,
  languagesListError: null,
});

const fetchLanguagesListFail = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  languagesListLoading: false,
  languagesListError: action.languagesListError,
});

const createLanguageStart = (state: LanguageStateType): LanguageStateType => ({
  ...state,
  languageCreateLoading: true,
});

const createLanguageSuccess = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  languageCreateLoading: false,
  createdLanguage: action.createdLanguage,
  languageCreateError: null,
  languageCreateSuccess: true,
  languagesListUpdateNeeded: true,
});

const createLanguageFail = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  languageCreateLoading: false,
  languageCreateError: action.languageCreateError,
});

const resetCreateLanguage = (state: LanguageStateType): LanguageStateType => ({
  ...state,
  languageCreateLoading: false,
  createdLanguage: null,
  languageCreateError: null,
  languageCreateSuccess: false,
  languagesListUpdateNeeded: false,
});

const changeLanguageStatusStart = (
  state: LanguageStateType,
): LanguageStateType => ({
  ...state,
  languageStatusChangeLoading: true,
});

const changeLanguageStatusSuccess = (
  state: LanguageStateType,
): LanguageStateType => ({
  ...state,
  languageStatusChangeLoading: false,
  languageStatusChangeError: null,
  languageStatusChangeSuccess: true,
  languagesListUpdateNeeded: true,
});

const changeLanguageStatusFail = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  languageStatusChangeLoading: false,
  languageStatusChangeError: action.languageCreateError,
});

const resetLanguageStatusChange = (
  state: LanguageStateType,
): LanguageStateType => ({
  ...state,
  languageStatusChangeLoading: false,
  languageStatusChangeError: null,
  languageStatusChangeSuccess: false,
  languagesListUpdateNeeded: false,
});

const updateLanguageStart = (state: LanguageStateType): LanguageStateType => ({
  ...state,
  languageUpdateLoading: true,
});

const updateLanguageSuccess = (
  state: LanguageStateType,
): LanguageStateType => ({
  ...state,
  languageUpdateLoading: false,
  languageUpdateError: null,
  languageUpdateSuccess: true,
  languagesListUpdateNeeded: true,
});

const updateLanguageFail = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  languageUpdateLoading: false,
  languageUpdateError: action.languageUpdateError,
});

const reducer = (state = initialState, action: LanguageActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_LANGUAGE_START:
      return fetchLanguageStart(state);
    case actionTypes.FETCH_LANGUAGE_SUCCESS:
      return fetchLanguageSuccess(state, action);
    case actionTypes.FETCH_LANGUAGE_FAIL:
      return fetchLanguageFail(state, action);
    case actionTypes.FETCH_LANGUAGES_START:
      return fetchLanguagesStart(state);
    case actionTypes.FETCH_LANGUAGES_SUCCESS:
      return fetchLanguagesSuccess(state, action);
    case actionTypes.FETCH_LANGUAGES_FAIL:
      return fetchLanguagesFail(state, action);
    case actionTypes.FETCH_LANGUAGES_LIST_START:
      return fetchLanguagesListStart(state);
    case actionTypes.FETCH_LANGUAGES_LIST_SUCCESS:
      return fetchLanguagesListSuccess(state, action);
    case actionTypes.FETCH_LANGUAGES_LIST_FAIL:
      return fetchLanguagesListFail(state, action);
    case actionTypes.CREATE_LANGUAGE_START:
      return createLanguageStart(state);
    case actionTypes.CREATE_LANGUAGE_SUCCESS:
      return createLanguageSuccess(state, action);
    case actionTypes.CREATE_LANGUAGE_FAIL:
      return createLanguageFail(state, action);
    case actionTypes.RESET_CREATE_LANGUAGE:
      return resetCreateLanguage(state);
    case actionTypes.CHANGE_LANGUAGE_STATUS_START:
      return changeLanguageStatusStart(state);
    case actionTypes.CHANGE_LANGUAGE_STATUS_SUCCESS:
      return changeLanguageStatusSuccess(state);
    case actionTypes.CHANGE_LANGUAGE_STATUS_FAIL:
      return changeLanguageStatusFail(state, action);
    case actionTypes.RESET_LANGUAGE_STATUS_CHANGE:
      return resetLanguageStatusChange(state);
    case actionTypes.UPDATE_LANGUAGE_START:
      return updateLanguageStart(state);
    case actionTypes.UPDATE_LANGUAGE_SUCCESS:
      return updateLanguageSuccess(state);
    case actionTypes.UPDATE_LANGUAGE_FAIL:
      return updateLanguageFail(state, action);
    default:
      return state;
  }
};

export default reducer;
