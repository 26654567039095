export const routes = {
  homepage: '/',
  login: '/login',
  profile: '/profile',
  dynamicPage: '/:locale/:slug',
  admin: '/admin',
  users: {
    list: '/admin/users',
    create: '/admin/users/new',
    edit: '/admin/users/:id',
  },
  staticPages: {
    list: '/admin/static-pages',
  },
  languages: '/admin/languages',
  skills: '/admin/skills',
  skillGroups: '/admin/skill-groups',
  questions: '/admin/questions',
  assessments: '/admin/assessments',
  translations: '/admin/translations',
  public: {
    customAssessment: '/assessment/custom',
    assessment: '/assessment/:id',
    privacyPolicy: '/privacy-policy',
    projectInformation: '/project-information',
  },
};
