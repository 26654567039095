import { IntlShape } from 'react-intl';
import { EntityTranslation } from '../../domain/EntityTranslation';
import { Language } from '../../domain/Language';

export const translate = (intl: IntlShape, id: string) =>
  intl.formatMessage({
    id,
    defaultMessage: id,
  });

export const translateProperty = (
  property: string,
  defaultValue: string,
  translations: EntityTranslation[],
  language: Language | null,
) => {
  if (!translations || !translations.length || !language) {
    return defaultValue;
  }

  const translation = translations.find(
    (translation) =>
      translation.property === property &&
      translation.languageId === language.id,
  );

  return translation ? translation.value : defaultValue;
};

export const getTranslationByKey = (
  entity: string,
  entityId: number,
  property: string,
  language: Language | null,
) => {
  if (!language) {
    return null;
  }

  return language.entityTranslations.find(
    (translation) =>
      translation.entityId === entityId &&
      translation.property === property &&
      translation.entity === entity,
  )?.value;
};
