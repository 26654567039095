export const FETCH_ASSESSMENTS_START = 'FETCH_ASSESSMENTS_START';
export const FETCH_ASSESSMENTS_SUCCESS = 'FETCH_ASSESSMENTS_SUCCESS';
export const FETCH_ASSESSMENTS_FAIL = 'FETCH_ASSESSMENTS_FAIL';

export const FETCH_PUBLIC_ASSESSMENTS_START = 'FETCH_PUBLIC_ASSESSMENTS_START';
export const FETCH_PUBLIC_ASSESSMENTS_SUCCESS =
  'FETCH_PUBLIC_ASSESSMENTS_SUCCESS';
export const FETCH_PUBLIC_ASSESSMENTS_FAIL = 'FETCH_PUBLIC_ASSESSMENTS_FAIL';

export const CREATE_ASSESSMENT_START = 'CREATE_ASSESSMENT_START';
export const CREATE_ASSESSMENT_SUCCESS = 'CREATE_ASSESSMENT_SUCCESS';
export const CREATE_ASSESSMENT_FAIL = 'CREATE_ASSESSMENT_FAIL';

export const UPDATE_ASSESSMENT_START = 'UPDATE_ASSESSMENT_START';
export const UPDATE_ASSESSMENT_SUCCESS = 'UPDATE_ASSESSMENT_SUCCESS';
export const UPDATE_ASSESSMENT_FAIL = 'UPDATE_ASSESSMENT_FAIL';

export const DELETE_ASSESSMENT_START = 'DELETE_ASSESSMENT_START';
export const DELETE_ASSESSMENT_SUCCESS = 'DELETE_ASSESSMENT_SUCCESS';
export const DELETE_ASSESSMENT_FAIL = 'DELETE_ASSESSMENT_FAIL';

export const FETCH_PUBLIC_ASSESSMENT_START = 'FETCH_PUBLIC_ASSESSMENT_START';
export const FETCH_PUBLIC_ASSESSMENT_SUCCESS =
  'FETCH_PUBLIC_ASSESSMENT_SUCCESS';
export const FETCH_PUBLIC_ASSESSMENT_FAIL = 'FETCH_PUBLIC_ASSESSMENT_FAIL';

export const SET_NEW_ASSESSMENT_DATA = 'SET_NEW_ASSESSMENT_DATA';
export const RESET_NEW_ASSESSMENT_DATA = 'RESET_NEW_ASSESSMENT_DATA';

export const RESET_ASSESSMENT_STORE = 'RESET_ASSESSMENT_STORE';

export type AssessmentActionTypes =
  | typeof FETCH_ASSESSMENTS_START
  | typeof FETCH_ASSESSMENTS_SUCCESS
  | typeof FETCH_ASSESSMENTS_FAIL
  | typeof FETCH_PUBLIC_ASSESSMENTS_START
  | typeof FETCH_PUBLIC_ASSESSMENTS_SUCCESS
  | typeof FETCH_PUBLIC_ASSESSMENTS_FAIL
  | typeof CREATE_ASSESSMENT_START
  | typeof CREATE_ASSESSMENT_SUCCESS
  | typeof CREATE_ASSESSMENT_FAIL
  | typeof UPDATE_ASSESSMENT_START
  | typeof UPDATE_ASSESSMENT_SUCCESS
  | typeof UPDATE_ASSESSMENT_FAIL
  | typeof DELETE_ASSESSMENT_START
  | typeof DELETE_ASSESSMENT_SUCCESS
  | typeof DELETE_ASSESSMENT_FAIL
  | typeof RESET_ASSESSMENT_STORE
  | typeof FETCH_PUBLIC_ASSESSMENT_START
  | typeof FETCH_PUBLIC_ASSESSMENT_SUCCESS
  | typeof FETCH_PUBLIC_ASSESSMENT_FAIL
  | typeof SET_NEW_ASSESSMENT_DATA
  | typeof RESET_NEW_ASSESSMENT_DATA;
