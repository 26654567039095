import axios from '../../config/Axios/axios-instance';
import {
  changeLanguageStatusFail,
  changeLanguageStatusStart,
  changeLanguageStatusSuccess,
  createLanguageFail,
  createLanguageStart,
  createLanguageSuccess,
  fetchLanguageFail,
  fetchLanguagesFail,
  fetchLanguagesListFail,
  fetchLanguagesListStart,
  fetchLanguagesListSuccess,
  fetchLanguagesStart,
  fetchLanguagesSuccess,
  fetchLanguageStart,
  fetchLanguageSuccess,
  updateLanguageFail,
  updateLanguageStart,
  updateLanguageSuccess,
} from './actions';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import { IntlShape } from 'react-intl';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';

const API_URL = '/languages';

export type LanguageCreateRequest = {
  name: string;
  locale: string;
};

export type LanguageUpdateRequest = {
  id: number;
  name: string;
  locale: string;
};

export const fetchLanguage = (locale: string) => (dispatch: Dispatch) => {
  dispatch(fetchLanguageStart());
  return axios
    .get(`${API_URL}/${locale}`)
    .then((response) => {
      dispatch(fetchLanguageSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchLanguageFail(err?.response?.data?.error));
    });
};

export const fetchAllLanguages = () => (dispatch: Dispatch) => {
  dispatch(fetchLanguagesStart());
  return axios
    .get(`${API_URL}/all`)
    .then((response) => {
      dispatch(fetchLanguagesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchLanguagesFail(err?.response?.data?.error));
    });
};

export const fetchLanguages = (params: ListParams) => (dispatch: Dispatch) => {
  dispatch(fetchLanguagesListStart());
  return axios
    .get(API_URL, { params })
    .then((response) => {
      dispatch(fetchLanguagesListSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchLanguagesListFail(err?.response?.data?.error));
    });
};

export const createLanguage =
  (inputs: LanguageCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createLanguageStart());
    return axios
      .post(API_URL, {
        ...inputs,
      })
      .then((response) => {
        dispatch(createLanguageSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.LANGUAGE_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createLanguageFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const changeLanguageStatus =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(changeLanguageStatusStart());
    return axios
      .patch(`${API_URL}/${id}/toggle-status`)
      .then(() => {
        dispatch(changeLanguageStatusSuccess());
        showToast(translate(intl, 'SUCCESS.LANGUAGE_STATUS_CHANGE'), 'success');
      })
      .catch((err) => {
        dispatch(changeLanguageStatusFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const updateLanguage =
  (inputs: LanguageUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateLanguageStart());
    return axios
      .patch(`${API_URL}/${inputs.id}`, {
        ...inputs,
      })
      .then(() => {
        showToast(translate(intl, 'SUCCESS.LANGUAGE_UPDATE'), 'success');
        dispatch(updateLanguageSuccess());
      })
      .catch((err) => {
        dispatch(updateLanguageFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };
