import * as actionTypes from './actionTypes';
import { StaticPageActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { StaticPage } from '../../domain/StaticPage';

export type StaticPageStateType = {
  staticPagesList: ListResults<StaticPage> | null;
  staticPagesLoading: boolean;
  staticPagesError: HttpError;
  staticPagesListUpdateNeeded: boolean;
  staticPageUpdateLoading: boolean;
  staticPageUpdateError: HttpError;
  staticPageUpdateSuccess: boolean;
  publicStaticPage: StaticPage | null;
  publicStaticPageLoading: boolean;
  publicStaticPageError: HttpError;
  publicStaticPageSuccess: boolean;
};

export type StaticPageActionType = StaticPageStateType & {
  type: StaticPageActionTypes;
};

export const initialState: StaticPageStateType = {
  staticPagesList: null,
  staticPagesLoading: true,
  staticPagesError: null,
  staticPagesListUpdateNeeded: false,
  staticPageUpdateLoading: false,
  staticPageUpdateError: null,
  staticPageUpdateSuccess: false,
  publicStaticPage: null,
  publicStaticPageLoading: true,
  publicStaticPageError: null,
  publicStaticPageSuccess: false,
};

const fetchStaticPagesStart = (
  state: StaticPageStateType,
): StaticPageStateType => ({
  ...state,
  staticPagesLoading: true,
});

const fetchStaticPagesSuccess = (
  state: StaticPageStateType,
  action: StaticPageActionType,
): StaticPageStateType => ({
  ...state,
  staticPagesList: action.staticPagesList,
  staticPagesLoading: false,
  staticPagesError: null,
  staticPagesListUpdateNeeded: false,
});

const fetchStaticPagesFail = (
  state: StaticPageStateType,
  action: StaticPageActionType,
): StaticPageStateType => ({
  ...state,
  staticPagesError: action.staticPagesError,
  staticPagesLoading: false,
});

const fetchPublicStaticPageStart = (
  state: StaticPageStateType,
): StaticPageStateType => ({
  ...state,
  publicStaticPageLoading: true,
});

const fetchPublicStaticPageSuccess = (
  state: StaticPageStateType,
  action: StaticPageActionType,
): StaticPageStateType => ({
  ...state,
  publicStaticPage: action.publicStaticPage,
  publicStaticPageLoading: false,
  publicStaticPageError: null,
  publicStaticPageSuccess: true,
});

const fetchPublicStaticPageFail = (
  state: StaticPageStateType,
  action: StaticPageActionType,
): StaticPageStateType => ({
  ...state,
  publicStaticPageError: action.publicStaticPageError,
  publicStaticPageLoading: false,
});

const updateStaticPageStart = (
  state: StaticPageStateType,
): StaticPageStateType => ({
  ...state,
  staticPageUpdateLoading: true,
});

const updateStaticPageSuccess = (
  state: StaticPageStateType,
): StaticPageStateType => ({
  ...state,
  staticPageUpdateLoading: false,
  staticPageUpdateError: null,
  staticPageUpdateSuccess: true,
  staticPagesListUpdateNeeded: true,
});

const updateStaticPageFail = (
  state: StaticPageStateType,
  action: StaticPageActionType,
): StaticPageStateType => ({
  ...state,
  staticPageUpdateLoading: false,
  staticPageUpdateError: action.staticPageUpdateError,
});

const resetPublicStaticPageStore = (
  state: StaticPageStateType,
): StaticPageStateType => ({
  ...state,
  publicStaticPage: null,
  publicStaticPageLoading: false,
  publicStaticPageError: null,
  publicStaticPageSuccess: false,
});

const resetStaticPageStore = (): StaticPageStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: StaticPageActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_STATIC_PAGES_START:
      return fetchStaticPagesStart(state);
    case actionTypes.FETCH_STATIC_PAGES_SUCCESS:
      return fetchStaticPagesSuccess(state, action);
    case actionTypes.FETCH_STATIC_PAGES_FAIL:
      return fetchStaticPagesFail(state, action);
    case actionTypes.FETCH_PUBLIC_STATIC_PAGE_START:
      return fetchPublicStaticPageStart(state);
    case actionTypes.FETCH_PUBLIC_STATIC_PAGE_SUCCESS:
      return fetchPublicStaticPageSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_STATIC_PAGE_FAIL:
      return fetchPublicStaticPageFail(state, action);
    case actionTypes.UPDATE_STATIC_PAGE_START:
      return updateStaticPageStart(state);
    case actionTypes.UPDATE_STATIC_PAGE_SUCCESS:
      return updateStaticPageSuccess(state);
    case actionTypes.UPDATE_STATIC_PAGE_FAIL:
      return updateStaticPageFail(state, action);
    case actionTypes.RESET_PUBLIC_STATIC_PAGE_STORE:
      return resetPublicStaticPageStore(state);
    case actionTypes.RESET_STATIC_PAGE_STORE:
      return resetStaticPageStore();
    default:
      return state;
  }
};

export default reducer;
