import * as actionTypes from './actionTypes';
import { SkillGroupActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { SkillGroup } from '../../domain/SkillGroup';

export type SkillGroupStateType = {
  skillGroupsList: ListResults<SkillGroup> | null;
  skillGroupsLoading: boolean;
  skillGroupsError: HttpError;
  skillGroupsListUpdateNeeded: boolean;
  skillGroupCreateLoading: boolean;
  skillGroupCreateError: HttpError;
  skillGroupCreateSuccess: boolean;
  createdSkillGroup: SkillGroup | null;
  skillGroupUpdateLoading: boolean;
  skillGroupUpdateError: HttpError;
  skillGroupUpdateSuccess: boolean;
  skillGroup: SkillGroup | null;
  skillGroupLoading: boolean;
  skillGroupError: HttpError;
  skillGroupDeleteLoading: boolean;
  skillGroupDeleteError: HttpError;
  skillGroupDeleteSuccess: boolean;
};

export type SkillGroupActionType = SkillGroupStateType & {
  type: SkillGroupActionTypes;
};

export const initialState: SkillGroupStateType = {
  skillGroupsList: null,
  skillGroupsLoading: true,
  skillGroupsError: null,
  skillGroupsListUpdateNeeded: false,
  skillGroupCreateLoading: false,
  skillGroupCreateError: null,
  skillGroupCreateSuccess: false,
  createdSkillGroup: null,
  skillGroupUpdateLoading: false,
  skillGroupUpdateError: null,
  skillGroupUpdateSuccess: false,
  skillGroup: null,
  skillGroupLoading: false,
  skillGroupError: null,
  skillGroupDeleteLoading: false,
  skillGroupDeleteError: null,
  skillGroupDeleteSuccess: false,
};

const fetchSkillGroupsStart = (
  state: SkillGroupStateType,
): SkillGroupStateType => ({
  ...state,
  skillGroupsLoading: true,
});

const fetchSkillGroupsSuccess = (
  state: SkillGroupStateType,
  action: SkillGroupActionType,
): SkillGroupStateType => ({
  ...state,
  skillGroupsList: action.skillGroupsList,
  skillGroupsLoading: false,
  skillGroupsError: null,
  skillGroupsListUpdateNeeded: false,
});

const fetchSkillGroupsFail = (
  state: SkillGroupStateType,
  action: SkillGroupActionType,
): SkillGroupStateType => ({
  ...state,
  skillGroupsError: action.skillGroupsError,
  skillGroupsLoading: false,
});

const createSkillGroupStart = (
  state: SkillGroupStateType,
): SkillGroupStateType => ({
  ...state,
  skillGroupCreateLoading: true,
});

const createSkillGroupSuccess = (
  state: SkillGroupStateType,
  action: SkillGroupActionType,
): SkillGroupStateType => ({
  ...state,
  skillGroupCreateLoading: false,
  skillGroupCreateError: null,
  skillGroupCreateSuccess: true,
  skillGroupsListUpdateNeeded: true,
  createdSkillGroup: action.createdSkillGroup,
  skillGroupsList: state.skillGroupsList
    ? {
        total: state.skillGroupsList.total + 1,
        result: [
          ...state.skillGroupsList.result,
          action.createdSkillGroup,
        ] as SkillGroup[],
      }
    : null,
});

const createSkillGroupFail = (
  state: SkillGroupStateType,
  action: SkillGroupActionType,
): SkillGroupStateType => ({
  ...state,
  skillGroupCreateLoading: false,
  skillGroupCreateError: action.skillGroupCreateError,
});

const updateSkillGroupStart = (
  state: SkillGroupStateType,
): SkillGroupStateType => ({
  ...state,
  skillGroupUpdateLoading: true,
});

const updateSkillGroupSuccess = (
  state: SkillGroupStateType,
): SkillGroupStateType => ({
  ...state,
  skillGroupUpdateLoading: false,
  skillGroupUpdateError: null,
  skillGroupUpdateSuccess: true,
  skillGroupsListUpdateNeeded: true,
});

const updateSkillGroupFail = (
  state: SkillGroupStateType,
  action: SkillGroupActionType,
): SkillGroupStateType => ({
  ...state,
  skillGroupUpdateLoading: false,
  skillGroupUpdateError: action.skillGroupUpdateError,
});

const deleteSkillGroupStart = (
  state: SkillGroupStateType,
): SkillGroupStateType => ({
  ...state,
  skillGroupDeleteLoading: true,
});

const deleteSkillGroupSuccess = (
  state: SkillGroupStateType,
): SkillGroupStateType => ({
  ...state,
  skillGroupDeleteLoading: false,
  skillGroupDeleteError: null,
  skillGroupDeleteSuccess: true,
  skillGroupsListUpdateNeeded: true,
});

const deleteSkillGroupFail = (
  state: SkillGroupStateType,
  action: SkillGroupActionType,
): SkillGroupStateType => ({
  ...state,
  skillGroupDeleteLoading: false,
  skillGroupDeleteError: action.skillGroupDeleteError,
});

const resetCreatedSkillGroup = (
  state: SkillGroupStateType,
): SkillGroupStateType => ({
  ...state,
  createdSkillGroup: null,
});

const resetSkillGroupStore = (): SkillGroupStateType => ({
  skillGroupsList: null,
  skillGroupsLoading: false,
  skillGroupsError: null,
  skillGroupsListUpdateNeeded: false,
  skillGroupCreateLoading: false,
  skillGroupCreateError: null,
  skillGroupCreateSuccess: false,
  createdSkillGroup: null,
  skillGroupUpdateLoading: false,
  skillGroupUpdateError: null,
  skillGroupUpdateSuccess: false,
  skillGroup: null,
  skillGroupLoading: false,
  skillGroupError: null,
  skillGroupDeleteLoading: false,
  skillGroupDeleteError: null,
  skillGroupDeleteSuccess: false,
});

const reducer = (state = initialState, action: SkillGroupActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_SKILL_GROUPS_START:
      return fetchSkillGroupsStart(state);
    case actionTypes.FETCH_SKILL_GROUPS_SUCCESS:
      return fetchSkillGroupsSuccess(state, action);
    case actionTypes.FETCH_SKILL_GROUPS_FAIL:
      return fetchSkillGroupsFail(state, action);
    case actionTypes.CREATE_SKILL_GROUP_START:
      return createSkillGroupStart(state);
    case actionTypes.CREATE_SKILL_GROUP_SUCCESS:
      return createSkillGroupSuccess(state, action);
    case actionTypes.CREATE_SKILL_GROUP_FAIL:
      return createSkillGroupFail(state, action);
    case actionTypes.UPDATE_SKILL_GROUP_START:
      return updateSkillGroupStart(state);
    case actionTypes.UPDATE_SKILL_GROUP_SUCCESS:
      return updateSkillGroupSuccess(state);
    case actionTypes.UPDATE_SKILL_GROUP_FAIL:
      return updateSkillGroupFail(state, action);
    case actionTypes.DELETE_SKILL_GROUP_START:
      return deleteSkillGroupStart(state);
    case actionTypes.DELETE_SKILL_GROUP_SUCCESS:
      return deleteSkillGroupSuccess(state);
    case actionTypes.DELETE_SKILL_GROUP_FAIL:
      return deleteSkillGroupFail(state, action);
    case actionTypes.RESET_SKILL_GROUP_STORE:
      return resetSkillGroupStore();
    case actionTypes.RESET_CREATED_SKILL_GROUP:
      return resetCreatedSkillGroup(state);
    default:
      return state;
  }
};

export default reducer;
