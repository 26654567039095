import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../common/Loader/Loader';
import { StoreState } from '../StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../store/auth/service';
import * as userService from '../../store/user/service';
import { routes } from './routes';
import Layout from '../../common/Layout/Layout';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { JwtToken } from '../Axios/axios-instance';
import { logout, selectLocale } from '../../store/auth/actions';
import * as languageService from '../../store/language/service';
import { Language } from '../../domain/Language';
import { DEFAULT_LOCALE } from '../constants';
import { Locale } from '../../domain/Translation';
import { IntlProvider } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../common/ErrorFallback/ErrorFallback';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';
import { DefaultStaticPages } from 'src/domain/StaticPage';

const LoginPage = React.lazy(
  () => import('../../pages/Public/LoginPage/LoginPage'),
);

const UsersListPage = React.lazy(
  () => import('../../pages/Admin/User/UsersListPage/UsersListPage'),
);

const UserCreatePage = React.lazy(
  () => import('../../pages/Admin/User/UserCreatePage/UserCreatePage'),
);

const UserEditPage = React.lazy(
  () => import('../../pages/Admin/User/UserEditPage/UserEditPage'),
);

const TranslationsPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Translation/TranslationListPage/TranslationListPage'
    ),
);

const SkillsPage = React.lazy(
  () => import('../../pages/Admin/Skill/SkillListPage/SkillListPage'),
);

const SkillGroupsPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/SkillGroup/SkillGroupListPage/SkillGroupListPage'
    ),
);

const QuestionsPage = React.lazy(
  () => import('../../pages/Admin/Question/QuestionListPage/QuestionListPage'),
);

const AssessmentPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Assessment/AssessmentListPage/AssessmentListPage'
    ),
);

const DynamicPage = React.lazy(
  () => import('../../pages/Public/DynamicPage/DynamicPage'),
);

const HomePage = React.lazy(
  () => import('../../pages/Public/HomePage/HomePage'),
);

const LanguagesListPage = React.lazy(
  () => import('../../pages/Admin/Language/LanguagesListPage'),
);

const StaticPagesListPage = React.lazy(
  () => import('../../pages/Admin/StaticPage/StaticPageListPage'),
);

const PublicStaticPage = React.lazy(
  () => import('../../pages/Public/PublicStaticPage/PublicStaticPage'),
);

const PublicAssessmentPage = React.lazy(
  () => import('../../pages/Public/AssessmentPage/AssessmentPage'),
);

const PublicCustomAssessmentPage = React.lazy(
  () => import('../../pages/Public/CustomAssessmentPage/CustomAssessmentPage'),
);

export type Props = {
  isInitCompleted: boolean;
  isAuthenticated: boolean;
  onTryAutoSignup: () => void;
  isCurrentUserLoading: boolean;
  refreshTokenLoading: boolean;
  onFetchCurrentUser: () => void;
  onRefreshToken: () => void;
  jwtToken: string | null;
  lastActionAt: moment.Moment | null;
  onLogout: () => void;
  onLanguageFetch: (locale: string) => void;
  language: Language | null;
  onLanguagesInit: () => void;
  onSelectLocale: (locale: Locale) => void;
  selectedLocale: Locale;
  languages: Language[] | null;
  currentUser: User | null;
  languageLoading: boolean;
};

const ALLOWED_LOCALES = ['en', 'lt'];

export const Router = ({
  isInitCompleted,
  isAuthenticated,
  onTryAutoSignup,
  isCurrentUserLoading,
  onFetchCurrentUser,
  refreshTokenLoading,
  onRefreshToken,
  jwtToken,
  lastActionAt,
  onLogout,
  onLanguageFetch,
  language,
  onLanguagesInit,
  selectedLocale,
  onSelectLocale,
  languages,
  currentUser,
  languageLoading,
}: Props) => {
  const locale =
    window.location?.pathname?.substring(1)?.split('/')?.[0] ?? DEFAULT_LOCALE;
  const parsedLocale = (
    locale && ALLOWED_LOCALES.includes(locale) ? locale : DEFAULT_LOCALE
  ) as Locale;

  useEffect(() => {
    if (!jwtToken) {
      return;
    }

    const decodedJson: JwtToken = jwtDecode(jwtToken);

    if (!decodedJson) {
      return;
    }

    const difference = moment.duration(
      moment(decodedJson.exp * 1000).diff(moment()),
    );
    const differenceLastAction = moment.duration(moment().diff(lastActionAt));

    if (
      difference.asMinutes() < 5 &&
      differenceLastAction.asMinutes() < 5 &&
      !refreshTokenLoading
    ) {
      onRefreshToken();
    }

    const timeout = setTimeout(() => {
      onLogout();
    }, difference.asMilliseconds());

    return () => clearTimeout(timeout);
  }, [jwtToken, lastActionAt]);

  useEffect(() => {
    onTryAutoSignup();
  }, []);

  useEffect(() => {
    onFetchCurrentUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      onFetchCurrentUser();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!selectedLocale) {
      onLanguageFetch(DEFAULT_LOCALE);
      return;
    }

    moment.locale(parsedLocale === 'en' ? 'en-gb' : parsedLocale);
    onLanguageFetch(selectedLocale);
    onLanguagesInit();
  }, [selectedLocale]);

  const mappedTranslations = language?.translations.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.alias]: item.value ? item.value : item.defaultValue,
      }),
    {},
  );

  const getRoutes = () => {
    if (!isAuthenticated) {
      return (
        <>
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.login} />}
          />
          <Route path={routes.login} element={<LoginPage />} />
          <Route path={routes.homepage} element={<HomePage />} />
          <Route
            path={routes.public.customAssessment}
            element={<PublicCustomAssessmentPage />}
          />
          <Route
            path={routes.public.assessment}
            element={<PublicAssessmentPage />}
          />
          <Route
            path={routes.public.privacyPolicy}
            element={
              <PublicStaticPage
                staticPageName={DefaultStaticPages.PRIVACY_POLICY}
              />
            }
          />
          <Route
            path={routes.public.projectInformation}
            element={
              <PublicStaticPage
                staticPageName={DefaultStaticPages.PROJECT_INFORMATION}
              />
            }
          />
          <Route path="*" element={<Navigate to={routes.homepage} />} />
        </>
      );
    }

    if (currentUser?.role === Roles.ADMIN) {
      return (
        <>
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.users.list} />}
          />
          <Route path={routes.users.create} element={<UserCreatePage />} />
          <Route path={routes.users.edit} element={<UserEditPage />} />
          <Route path={routes.users.list} element={<UsersListPage />} />
          <Route path={routes.translations} element={<TranslationsPage />} />
          <Route path={routes.skills} element={<SkillsPage />} />
          <Route path={routes.skillGroups} element={<SkillGroupsPage />} />
          <Route path={routes.questions} element={<QuestionsPage />} />
          <Route path={routes.assessments} element={<AssessmentPage />} />
          <Route path={routes.languages} element={<LanguagesListPage />} />
          <Route
            path={routes.staticPages.list}
            element={<StaticPagesListPage />}
          />
          <Route path={routes.dynamicPage} element={<DynamicPage />} />
          <Route path={routes.homepage} element={<HomePage />} />
          <Route
            path={routes.public.customAssessment}
            element={<PublicCustomAssessmentPage />}
          />
          <Route
            path={routes.public.assessment}
            element={<PublicAssessmentPage />}
          />
          <Route
            path={routes.public.privacyPolicy}
            element={
              <PublicStaticPage
                staticPageName={DefaultStaticPages.PRIVACY_POLICY}
              />
            }
          />
          <Route
            path={routes.public.projectInformation}
            element={
              <PublicStaticPage
                staticPageName={DefaultStaticPages.PROJECT_INFORMATION}
              />
            }
          />
          <Route path="*" element={<Navigate to={routes.admin} />} />
        </>
      );
    }
  };

  return (
    <BrowserRouter basename="/">
      {isInitCompleted && !isCurrentUserLoading && language ? (
        <IntlProvider
          messages={mappedTranslations}
          locale={language?.locale ?? DEFAULT_LOCALE}
          defaultLocale="en"
        >
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Suspense fallback={<Loader isLoading isFullScreen />}>
              <Loader isLoading={languageLoading}>
                <Layout isAuthenticated={isAuthenticated}>
                  <Routes>{getRoutes()}</Routes>
                </Layout>
              </Loader>
            </Suspense>
          </ErrorBoundary>
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isInitCompleted: state.auth.isInitCompleted,
  isAuthenticated: state.auth.isAuthenticated,
  isCurrentUserLoading: state.user.currentUserLoading,
  refreshTokenLoading: state.auth.refreshTokenLoading,
  jwtToken: state.auth.jwtToken,
  lastActionAt: state.auth.lastStoreActionAt,
  language: state.language.language,
  selectedLocale: state.auth.selectedLocale,
  languages: state.language.languages,
  currentUser: state.user.currentUser,
  languageLoading: state.language.languageLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onTryAutoSignup: () => dispatch(authService.authCheckState()),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser()),
  onRefreshToken: () => dispatch(authService.refreshToken()),
  onLanguageFetch: (locale: string) =>
    dispatch(languageService.fetchLanguage(locale)),
  onLanguagesInit: () => dispatch(languageService.fetchAllLanguages()),
  onLogout: () => dispatch(logout()),
  onSelectLocale: (locale: Locale) => dispatch(selectLocale(locale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
