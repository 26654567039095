import * as actionTypes from './actionTypes';
import { AssessmentActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { AssessmentData, Assessment } from '../../domain/Assessment';
import { LOCAL_STORAGE_ASSESSMENT_DATA } from '../../config/constants';

const getStoredAssesmentData = (): Array<AssessmentData> | [] => {
  const storedAssessmentData = localStorage.getItem(
    LOCAL_STORAGE_ASSESSMENT_DATA,
  );

  if (!storedAssessmentData) {
    return [];
  }

  return JSON.parse(storedAssessmentData);
};

export type AssessmentStateType = {
  assessmentsList: ListResults<Assessment> | null;
  assessmentsLoading: boolean;
  assessmentsError: HttpError;
  publicAssessmentsList: ListResults<Assessment> | null;
  publicAssessmentsLoading: boolean;
  publicAssessmentsError: HttpError;
  assessmentsListUpdateNeeded: boolean;
  assessmentCreateLoading: boolean;
  assessmentCreateError: HttpError;
  assessmentUpdateLoading: boolean;
  assessmentUpdateError: HttpError;
  assessmentDeleteLoading: boolean;
  assessmentDeleteError: HttpError;
  publicAssessment: Assessment | null;
  publicAssessmentLoading: boolean;
  publicAssessmentError: HttpError;
  assessmentData: Array<AssessmentData> | [];
  newAssessmentData: AssessmentData | null;
};

export type AssessmentActionType = AssessmentStateType & {
  type: AssessmentActionTypes;
};

export const initialState: AssessmentStateType = {
  assessmentsList: null,
  assessmentsLoading: false,
  assessmentsListUpdateNeeded: false,
  publicAssessmentsList: null,
  publicAssessmentsLoading: false,
  publicAssessmentsError: null,
  assessmentsError: null,
  assessmentCreateLoading: false,
  assessmentCreateError: null,
  assessmentUpdateLoading: false,
  assessmentUpdateError: null,
  assessmentDeleteLoading: false,
  assessmentDeleteError: null,
  publicAssessment: null,
  publicAssessmentLoading: false,
  publicAssessmentError: null,
  assessmentData: getStoredAssesmentData(),
  newAssessmentData: null,
};

const fetchAssessmentsStart = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  assessmentsLoading: true,
});

const fetchAssessmentsSuccess = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  assessmentsList: action.assessmentsList,
  assessmentsLoading: false,
  assessmentsError: null,
  assessmentsListUpdateNeeded: false,
});

const fetchAssessmentsFail = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  assessmentsError: action.assessmentsError,
  assessmentsLoading: false,
});

const fetchPublicAssessmentsStart = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  publicAssessmentsLoading: true,
});

const fetchPublicAssessmentsSuccess = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  publicAssessmentsList: action.publicAssessmentsList,
  publicAssessmentsLoading: false,
  publicAssessmentsError: null,
});

const fetchPublicAssessmentsFail = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  publicAssessmentsError: action.publicAssessmentsError,
  publicAssessmentsLoading: false,
});

const createAssessmentStart = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  assessmentCreateLoading: true,
});

const createAssessmentSuccess = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  assessmentCreateLoading: false,
  assessmentCreateError: null,
  assessmentsListUpdateNeeded: true,
});

const createAssessmentFail = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  assessmentCreateLoading: false,
  assessmentCreateError: action.assessmentCreateError,
});

const updateAssessmentStart = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  assessmentUpdateLoading: true,
});

const updateAssessmentSuccess = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  assessmentUpdateLoading: false,
  assessmentUpdateError: null,
  assessmentsListUpdateNeeded: true,
});

const updateAssessmentFail = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  assessmentUpdateLoading: false,
  assessmentUpdateError: action.assessmentUpdateError,
});

const deleteAssessmentStart = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  assessmentDeleteLoading: true,
});

const deleteAssessmentSuccess = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  assessmentDeleteLoading: false,
  assessmentDeleteError: null,
  assessmentsListUpdateNeeded: true,
});

const deleteAssessmentFail = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  assessmentDeleteLoading: false,
  assessmentDeleteError: action.assessmentDeleteError,
});

const fetchPublicAssessmentStart = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  publicAssessmentLoading: true,
});

const fetchPublicAssessmentSuccess = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  publicAssessment: action.publicAssessment,
  publicAssessmentLoading: false,
  assessmentsError: null,
});

const fetchPublicAssessmentFail = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  publicAssessmentError: action.publicAssessmentError,
  publicAssessmentLoading: false,
});

const setNewAssessmentData = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => {
  const addedData = state.assessmentData;

  // @ts-ignore
  addedData.push(action.newAssessmentData);

  localStorage.setItem(
    LOCAL_STORAGE_ASSESSMENT_DATA,
    JSON.stringify(addedData),
  );

  return {
    ...state,
    assessmentData: addedData,
  };
};

const resetNewAssessmentData = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  newAssessmentData: null,
});

const resetAssessmentStore = (): AssessmentStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: AssessmentActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ASSESSMENTS_START:
      return fetchAssessmentsStart(state);
    case actionTypes.FETCH_ASSESSMENTS_SUCCESS:
      return fetchAssessmentsSuccess(state, action);
    case actionTypes.FETCH_ASSESSMENTS_FAIL:
      return fetchAssessmentsFail(state, action);
    case actionTypes.FETCH_PUBLIC_ASSESSMENTS_START:
      return fetchPublicAssessmentsStart(state);
    case actionTypes.FETCH_PUBLIC_ASSESSMENTS_SUCCESS:
      return fetchPublicAssessmentsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_ASSESSMENTS_FAIL:
      return fetchPublicAssessmentsFail(state, action);
    case actionTypes.CREATE_ASSESSMENT_START:
      return createAssessmentStart(state);
    case actionTypes.CREATE_ASSESSMENT_SUCCESS:
      return createAssessmentSuccess(state);
    case actionTypes.CREATE_ASSESSMENT_FAIL:
      return createAssessmentFail(state, action);
    case actionTypes.UPDATE_ASSESSMENT_START:
      return updateAssessmentStart(state);
    case actionTypes.UPDATE_ASSESSMENT_SUCCESS:
      return updateAssessmentSuccess(state);
    case actionTypes.UPDATE_ASSESSMENT_FAIL:
      return updateAssessmentFail(state, action);
    case actionTypes.DELETE_ASSESSMENT_START:
      return deleteAssessmentStart(state);
    case actionTypes.DELETE_ASSESSMENT_SUCCESS:
      return deleteAssessmentSuccess(state);
    case actionTypes.DELETE_ASSESSMENT_FAIL:
      return deleteAssessmentFail(state, action);
    case actionTypes.FETCH_PUBLIC_ASSESSMENT_START:
      return fetchPublicAssessmentStart(state);
    case actionTypes.FETCH_PUBLIC_ASSESSMENT_SUCCESS:
      return fetchPublicAssessmentSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_ASSESSMENT_FAIL:
      return fetchPublicAssessmentFail(state, action);
    case actionTypes.SET_NEW_ASSESSMENT_DATA:
      return setNewAssessmentData(state, action);
    case actionTypes.RESET_NEW_ASSESSMENT_DATA:
      return resetNewAssessmentData(state);
    case actionTypes.RESET_ASSESSMENT_STORE:
      return resetAssessmentStore();
    default:
      return state;
  }
};

export default reducer;
