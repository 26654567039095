import * as actionTypes from './actionTypes';
import { AnswerActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Answer } from '../../domain/Answer';

export type AnswerStateType = {
  answersListUpdateNeeded: boolean;
  answerCreateLoading: boolean;
  answerCreateError: HttpError;
  answerUpdateLoading: boolean;
  answerUpdateError: HttpError;
  answerDeleteLoading: boolean;
  answerDeleteError: HttpError;
  selectedAnswers: Answer[] | null;
};

export type AnswerActionType = AnswerStateType & {
  type: AnswerActionTypes;
};

export const initialState: AnswerStateType = {
  answersListUpdateNeeded: false,
  answerCreateLoading: false,
  answerCreateError: null,
  answerUpdateLoading: false,
  answerUpdateError: null,
  answerDeleteLoading: false,
  answerDeleteError: null,
  selectedAnswers: null,
};

const createAnswerStart = (state: AnswerStateType): AnswerStateType => ({
  ...state,
  answerCreateLoading: true,
});

const createAnswerSuccess = (state: AnswerStateType): AnswerStateType => ({
  ...state,
  answerCreateLoading: false,
  answerCreateError: null,
  answersListUpdateNeeded: true,
});

const createAnswerFail = (
  state: AnswerStateType,
  action: AnswerActionType,
): AnswerStateType => ({
  ...state,
  answerCreateLoading: false,
  answerCreateError: action.answerCreateError,
});

const updateAnswerStart = (state: AnswerStateType): AnswerStateType => ({
  ...state,
  answerUpdateLoading: true,
});

const updateAnswerSuccess = (state: AnswerStateType): AnswerStateType => ({
  ...state,
  answerUpdateLoading: false,
  answerUpdateError: null,
  answersListUpdateNeeded: true,
});

const updateAnswerFail = (
  state: AnswerStateType,
  action: AnswerActionType,
): AnswerStateType => ({
  ...state,
  answerUpdateLoading: false,
  answerUpdateError: action.answerUpdateError,
});

const deleteAnswerStart = (state: AnswerStateType): AnswerStateType => ({
  ...state,
  answerDeleteLoading: true,
});

const deleteAnswerSuccess = (state: AnswerStateType): AnswerStateType => ({
  ...state,
  answerDeleteLoading: false,
  answerDeleteError: null,
  answersListUpdateNeeded: true,
});

const deleteAnswerFail = (
  state: AnswerStateType,
  action: AnswerActionType,
): AnswerStateType => ({
  ...state,
  answerDeleteLoading: false,
  answerDeleteError: action.answerDeleteError,
});

const resetAnswerStore = (): AnswerStateType => ({
  ...initialState,
});

const setSelectedAnswers = (
  state: AnswerStateType,
  action: AnswerActionType,
): AnswerStateType => ({
  ...state,
  selectedAnswers: action.selectedAnswers,
});

const resetSelectedAnswers = (state: AnswerStateType): AnswerStateType => ({
  ...state,
  selectedAnswers: null,
});

const reducer = (state = initialState, action: AnswerActionType) => {
  switch (action.type) {
    case actionTypes.CREATE_ANSWER_START:
      return createAnswerStart(state);
    case actionTypes.CREATE_ANSWER_SUCCESS:
      return createAnswerSuccess(state);
    case actionTypes.CREATE_ANSWER_FAIL:
      return createAnswerFail(state, action);
    case actionTypes.UPDATE_ANSWER_START:
      return updateAnswerStart(state);
    case actionTypes.UPDATE_ANSWER_SUCCESS:
      return updateAnswerSuccess(state);
    case actionTypes.UPDATE_ANSWER_FAIL:
      return updateAnswerFail(state, action);
    case actionTypes.DELETE_ANSWER_START:
      return deleteAnswerStart(state);
    case actionTypes.DELETE_ANSWER_SUCCESS:
      return deleteAnswerSuccess(state);
    case actionTypes.DELETE_ANSWER_FAIL:
      return deleteAnswerFail(state, action);
    case actionTypes.RESET_ANSWER_STORE:
      return resetAnswerStore();
    case actionTypes.SET_SELECTED_ANSWERS:
      return setSelectedAnswers(state, action);
    case actionTypes.RESET_ANSWER_STORE:
      return resetSelectedAnswers(state);
    default:
      return state;
  }
};

export default reducer;
