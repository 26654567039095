import React, { useMemo, useState } from 'react';
import styles from './LanguageSwitcher.module.scss';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { selectLocale } from '../../store/auth/actions';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { Popover } from '@mui/material';
import { Locale } from '../../domain/Translation';
import { Language } from '../../domain/Language';

type Props = {
  onSelectLocale: (locale: Locale) => void;
  selectedLocale: Locale;
  languages: Language[];
  languageSwitcherPopoverListRef?: React.RefObject<HTMLUListElement>;
  closeMobileMenu?: () => void;
};

const LanguageSwitcher = ({
  onSelectLocale,
  selectedLocale,
  languages,
  languageSwitcherPopoverListRef,
  closeMobileMenu,
}: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const languageOptions = useMemo(
    () =>
      languages?.map((language) => ({
        label: language.locale.toUpperCase(),
        value: language.locale,
      })) ?? [],
    [languages],
  );

  const selectedLocaleOption = languageOptions.find(
    (locale) => locale.value === selectedLocale,
  );

  const handleClick = (locale: Locale) => {
    onSelectLocale(locale);
    setIsPopoverOpen(false);
    closeMobileMenu && closeMobileMenu();
  };

  const renderActions = () => {
    return languageOptions.map((language, index) => (
      <button
        className={styles.popoverListItem}
        key={index.toString()}
        onClick={() => handleClick(language.value as Locale)}
      >
        {language?.label}
      </button>
    ));
  };

  return (
    <>
      <div
        className={styles.switcherButton}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setIsPopoverOpen(true);
        }}
      >
        {selectedLocaleOption?.label}
      </div>
      <Popover
        className={styles.popover}
        open={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={styles.popoverContent}>
          <ul
            className={styles.popoverList}
            ref={languageSwitcherPopoverListRef}
          >
            {renderActions()}
          </ul>
        </div>
      </Popover>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
  languages: state.language.languages,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onSelectLocale: (locale: Locale) => dispatch(selectLocale(locale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
